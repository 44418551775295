import styled, { css } from "styled-components";

const vertical = css`
  &:not(:last-of-type) {
    margin-bottom: 32px;
  }
`;

const horizontal = css`
  &:not(:last-of-type) {
    @media (max-width: 1399px) {
      margin-right: 28px;
    }

    @media (min-width: 1400px) {
      /* margin-right: 18px; */
    }
  }
`;

export const SocialLink = styled.a`
  @media (max-width: 1399px) {
    width: 38px;
    height: 38px;
    color: #c3c3c3;
  }

  @media (min-width: 1400px) {
    width: 48px;
    height: 48px;
    padding: 12px;
    color: #fff;

    &:hover {
      color: rgb(196, 159, 63);
    }
  }
`;

export const Root = styled.ul`
  display: flex;
  flex-direction: ${p => p.$direction === "vertical" && "column"};

  ${SocialLink} {
    ${p => (p.$direction === "vertical" ? vertical : horizontal)};
  }
`;
