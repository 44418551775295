import React from "react";

import { Seo } from "../components/Seo";
import Rules from "../components/Rules/Rules";
import Footer from "../components/Footer/Footer";

const PrivacyPolicyPage = () => {
  return (
    <>
      <Seo />
      <Rules />
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
