import React, { useState } from "react";
import { Link } from "gatsby";
import { useForm } from "react-hook-form";

import {
  Root,
  Container,
  Logo,
  Body,
  Title,
  NavList,
  NavLink,
  SocialContainer,
  ToTopButton,
  NavWrapper,
  SocialWrapper,
  CtaContainer,
  CtaWrapper,
  EmailCTAContainer,
} from "./Footer.style";

import logoSrc from "../../images/logo-footer@2x.png";

import Social from "../Social/Social";
import ModalEntry from "../ModalEntry/ModalEntry";
import ModalRules from "../ModalRules/ModalRules";
import EmailCTA from "../EmailCTA/EmailCTA";

const Footer = () => {
  const [entryShow, setEntryShow] = useState(false);
  const [rulesShow, setRulesShow] = useState(false);
  const [rulesFromEntry, setRulesFromEntry] = useState(false);

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  return (
    <Root>
      <Container>
        <Logo as={Link} to="/">
          <img src={logoSrc} alt="Ole Smoky" />
        </Logo>

        <Body>
          <NavWrapper>
            <Title>SUPPORT</Title>
            <NavList>
              <li>
                <NavLink as="button" onClick={() => setRulesShow(true)}>
                  Sweepstakes Rules
                </NavLink>
              </li>
              <li>
                <NavLink
                  target="_blank"
                  href="https://olesmoky.com/pages/privacy"
                >
                  Privacy policy
                </NavLink>
              </li>
              <li>
                <NavLink
                  target="_blank"
                  href="https://olesmoky.com/pages/contact"
                >
                  Contact
                </NavLink>
              </li>
            </NavList>
          </NavWrapper>

          <SocialWrapper>
            <Title className="--social">SOCIAL</Title>
            <SocialContainer>
              <Social />
            </SocialContainer>
          </SocialWrapper>

          <CtaContainer>
            <CtaWrapper>
              <Title>Enter for a chance to win</Title>
              <EmailCTAContainer>
                <EmailCTA />
              </EmailCTAContainer>
            </CtaWrapper>
            <ToTopButton href="/#home" />
          </CtaContainer>
        </Body>
      </Container>

      {entryShow && (
        <ModalEntry
          initialEmail={getValues("email")}
          onClose={() => setEntryShow(false)}
          onRulesShow={() => {
            setRulesFromEntry(true);
            setEntryShow(false);
            setRulesShow(true);
          }}
        />
      )}

      {rulesShow && (
        <ModalRules
          onClose={() => {
            setRulesShow(false);
            if (rulesFromEntry) {
              setRulesFromEntry(false);
              setEntryShow(true);
            }
          }}
        />
      )}
    </Root>
  );
};

export default Footer;
