import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  @media (max-width: 1399px) {
    padding-top: 35px;
    padding-bottom: 35px;
    background-color: #000;
  }

  @media (min-width: 1400px) {
    padding: 0 60px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1760px;

  @media (max-width: 1399px) {
    flex-direction: column;
  }

  @media (min-width: 1400px) {
    padding: 55px 0;
    background-image: linear-gradient(
      to right,
      #818386 60%,
      rgba(255, 255, 255, 0) 0%
    );
    background-size: 20px 1px;
    background-repeat: repeat-x;
    background-position-y: 0;
  }
`;

export const Logo = styled.a`
  width: 100%;

  @media (max-width: 1399px) {
    max-width: 120px;
    margin-bottom: 34px;
  }

  @media (min-width: 1400px) {
    max-width: 165px;
  }
`;

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 1399px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  @media (min-width: 1400px) {
    width: 80%;
  }
`;

export const Title = styled.div`
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;

  @media (max-width: 1399px) {
    font-size: 18px;
    letter-spacing: 1.44px;

    &.--social {
      display: none;
    }
  }

  @media (min-width: 1400px) {
    font-size: 20px;
    letter-spacing: 1.6px;
  }
`;

export const NavList = styled.ul`
  margin-top: 12px;

  @media (max-width: 1399px) {
    text-align: center;
  }
`;

export const NavLink = styled.a`
  display: inline-block;
  padding: 0;
  color: #c3c3c3;
  font-family: inherit;
  text-decoration: none;

  &:hover {
    color: #fff;
  }

  @media (max-width: 1399px) {
    font-size: 14px;
    line-height: 1.86;
    text-align: center;
  }

  @media (min-width: 1400px) {
    font-size: 16px;
    line-height: 1.75;
  }
`;

export const SocialContainer = styled.div`
  @media (min-width: 1400px) {
    margin-top: 24px;
    transform: translateX(-12px);
  }
`;

export const NavWrapper = styled.div`
  @media (max-width: 1399px) {
    order: 1;
  }

  @media (min-width: 1400px) {
    width: 15%;
  }

  @media (min-width: 1620px) {
    width: 25%;
  }
`;

export const SocialWrapper = styled.div`
  @media (max-width: 1399px) {
    order: 0;
    margin-bottom: 39px;
  }

  @media (min-width: 1400px) {
    width: 19%;
  }

  @media (min-width: 1620px) {
    width: 25%;
  }
`;

export const CtaContainer = styled.div`
  @media (max-width: 1399px) {
    order: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }

  @media (min-width: 1400px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 60%;
  }

  @media (min-width: 1620px) {
    width: 50%;
  }
`;

export const CtaWrapper = styled.div`
  @media (max-width: 1399px) {
  }

  @media (min-width: 1400px) {
    width: 100%;
  }
`;

export const EmailCTAContainer = styled.div`
  margin-top: 20px;

  @media (min-width: 1400px) {
    width: 100%;
  }
`;

export const ToTopButton = styled.a`
  display: block;
  width: 50px;
  height: 50px;
  background-color: #222;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' d='M 1.645 11.323 L 7 5.979 L 12.355 11.323 L 14 9.678 L 7 2.678 L 0 9.678 L 1.645 11.323 Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
  border-radius: 25px;

  @media (max-width: 1399px) {
    margin-top: 50px;
  }

  @media (min-width: 1400px) {
    margin-left: 16px;
  }
`;
